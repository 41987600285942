<!--
@description: about介绍
@author: fc
@createtime: 2021年11月21日 17:51:15
-->
<template>
  <div class="about-container">
    <div class="about-content">
      <div class="about-title">
        <img class="about-logo" src="../../assets/about_logo.png" />
        <div class="about-nav" @click="handleClick">$COOP GitHub</div>
      </div>
      <div class="about-white-paper">
        <p class="paper-content">{{ whitePaper[0] }}</p>
        <p class="paper-content">{{ whitePaper[1] }}</p>
        <p class="paper-content">{{ whitePaper[2] }}</p>
        <p class="paper-content">{{ whitePaper[3] }}</p>
        <p class="paper-content links">
          <span>See more at</span>
          <a
            href="https://github.com/CAAINS/COOP/blob/main/Chinese_whitepaper.md"
            >中文白皮书</a
          >
          <a href="https://github.com/CAAINS/COOP/blob/main/en_whitepapter.md"
            >English whitepaper</a
          >
          <a href="https://discord.gg/wevcCgVrvK">join discord</a>
        </p>
      </div>
      <div class="about-back">
        <img src="../../assets/monster-2.png" id="about-monster-2" />
        <img src="../../assets/monster-3.png" id="about-monster-3" />
      </div>
    </div>
    <MFooter style="position: relative"></MFooter>
  </div>
</template>

<script>
import MFooter from "../footer/footer_pc.vue";
const WHITEPAPER = require("../../data/whitepaper.json");
export default {
  components: {
    MFooter,
  },
  beforeMount() {
    this.whitePaper = WHITEPAPER;
  },
  methods: {
    handleClick() {
      window.open("https://github.com/CAAINS/coop");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";
$about-logo: 178px;
.about-container {
  margin-top: 228px;
  width: 100%;
  height: 100%;
  background: #fffae9;
  border-radius: 20px 20px 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  .about-content {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    flex: 1;
    .about-title {
      color: $color-text;
      font-size: 2rem;
      margin: 80px 80px 80px 120px;
      .about-logo {
        width: $about-logo;
        height: $about-logo;
      }
      .about-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $about-logo;
        height: 44px;
        font-size: 1.8rem;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.88);
        box-sizing: border-box;
        box-shadow: 0px 16px 32px rgba(23, 18, 46, 0.16);
        border-radius: 50px;
        margin: 10px 0px;
      }
      .about-nav:hover {
        color: #03a9f4;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .about-white-paper {
      z-index: 1;
      width: calc(50% - 190px);
      min-width: 400px;
      .paper-content {
        color: $color-text;
        font-size: 2rem;
      }
      .links {
        display: flex;
        flex-direction: column;
        a {
          color: #adadad;
          width: fit-content;
          display: block;
          border-bottom: 1px solid #adadad;
          font-size: 1.8rem;
          margin-top: 2rem;
        }
      }
    }
    .about-back {
      #about-monster-2 {
        position: absolute;
        right: 100px;
        top: 120px;
        width: 500px;
      }
      #about-monster-3 {
        position: absolute;
        right: 280px;
        top: 140px;
        width: 340px;
      }
    }
  }
}
</style>
